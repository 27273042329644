import React from 'react'
import {Link} from "gatsby"

const BlogItem = ({title, slug, cover, id}) => {
    return (
        <article id={id}>
            <Link className="group overflow-hidden" to={`/${slug}`} alt={title}>
                <img src={cover} alt={title} className="w-full h-auto block rounded-lg shadow-xl group-hover:shadow-2xl transform group-hover:-translate-y-1 transition-all duration-200 ease-in-out" />
            </Link>
        </article>
    )
}

export default BlogItem
